<template>
<div>
  <b-row class="mt-4">
    <b-col cols="1"></b-col>
    <b-col cols="11" >
      <v-img src="https://eligeganar.com/logo/mobil-color.png" max-width="150" :style="!isMobile ? 'margin-left:8%' : ''"></v-img>
     </b-col>
  </b-row>

<hr style=" border-top: 3px solid #979797 !important;">
  <v-container class="mt-5">
  <br><br>
  <v-dialog 
        transition="dialog-top-transition"
        :max-width="grafico != 1 ? '1000' : '1600'"
        v-model="dialog"
        persistent 
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#013352"
            v-bind="attrs"
            v-on="on"
            dark
            style="text-transform: none;"
          >Crea una nueva promoción</v-btn>
        </template>
        <template v-slot:default>
          <v-card>
           <b-form  @submit="store">
            <v-toolbar  color="#013352"   dark >
                <v-toolbar-title v-if="grafico == 0">Crea una nueva promoción </v-toolbar-title>
                <v-toolbar-title v-if="grafico == 2">Actualizar Legales</v-toolbar-title>
                <v-toolbar-title  v-if="grafico == 1">Gráficos de promoción</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                   <v-btn  text   @click="clear()" >
                        Cerrar
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>            
                <div v-if="grafico == 0">
                    <b-form-group>
                        <h6 style="color:#013352;">Nombre de la promoción</h6>
                        <b-form-input v-model="promo" placeholder="Nombre de la promoción"></b-form-input>
                        <small class="text-danger" v-if="errors.includes( 'promo' )">Campo requerido</small>
                    </b-form-group>                   
                    <b-row class="mt-4">
                        <b-col cols="12" md="4" >
                        <b-form-group>
                            <h6 style="color:#013352;">
                            Banner Principal
                            </h6>
                            <v-file-input class="mt-4"
                                v-model="imagen1"
                                label="Selecciona un archivo"
                                
                            ></v-file-input>
                            <small class="text-secondary"><em>Medida  1920 x 1000 px</em></small>
                        </b-form-group>
                         <small class="text-danger" v-if="errors.includes( 'imagen1' )">Campo requerido</small>
                        </b-col>
                        <b-col cols="12" md="4" >
                            <b-form-group>
                                <h6 style="color:#013352;">
                                Banner Móvil
                                </h6>
                                <v-file-input class="mt-4"
                                    v-model="imagen2"
                                    label="Selecciona un archivo"
                                
                                ></v-file-input>
                                <small class="text-secondary"><em>Medida 640 x 800 px</em></small>
                            </b-form-group>  
                            <small class="text-danger" v-if="errors.includes( 'imagen2' )">Campo requerido</small>               
                        </b-col>
                        <b-col cols="12" md="4" >
                            <b-form-group>
                                <h6 style="color:#013352;">
                                    Banner Dashboard
                                </h6>
                                <v-file-input class="mt-4"
                                    v-model="imagen3"
                                    label="Selecciona un archivo"
                                
                                ></v-file-input>
                                <small class="text-secondary"><em>Medida 1168 X 1167 px</em></small>
                            </b-form-group>   
                             <small class="text-danger" v-if="errors.includes( 'imagen3' )">Campo requerido</small>                 
                        </b-col>
                        <b-col cols="12" md="12" class="text-center" v-if="editMode">
                            <v-dialog
                                transition="dialog-bottom-transition"
                                max-width="900"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="#013352"
                                    v-bind="attrs"
                                    v-on="on"
                                    dark
                                    style="text-transform: none;"
                                    small
                                >Vista previa</v-btn>
                                </template>
                                <template v-slot:default="dialog">
                                <v-card>
                                    <v-toolbar
                                    color="#013352"
                                    dark
                                    >Vista previa</v-toolbar>
                                    <v-card-text>
                                        <br>
                                      <h2 class="text-center" style="color:#013352;">Principal</h2>
                                      <br>
                                        <template v-if="editMode">
                                            <v-img :src="'https://api.eligeganar.com/storage/banner/' + Verimagen1" class="grey darken-4 "   max-width="1000"></v-img>
                                        </template>
                                        <template v-else>

                                        </template>
                                      <hr>
                                      <h2 class="text-center" style="color:#013352;">Movil</h2>
                                      <br>
                                        <template v-if="editMode">
                                            <center><v-img :src="'https://api.eligeganar.com/storage/banner/' + Verimagen2" class="grey darken-4" max-width="400"></v-img></center>                                            
                                        </template>
                                        <template v-else>

                                        </template>
                                      <hr>
                                      <h2 class="text-center" style="color:#013352;">Dashboard</h2>
                                      <br>
                                        <template v-if="editMode">
                                            <center><v-img :src="'https://api.eligeganar.com/storage/banner/' + Verimagen3" class="grey darken-4 " max-width="500"></v-img></center>
                                        </template>
                                        <template v-else>

                                        </template>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        @click="dialog.value = false"
                                    >Cerrar</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </template>
                            </v-dialog>
                            
                        </b-col>
                         <b-col cols="12"><hr></b-col>
                        <b-col cols="12" md="6">
                            <h6 style="color:#013352;">
                            Agrega los datos para el registro:
                            </h6>
                            <div class="form-check" >
                                <v-checkbox  v-model="fecha"
                                    label="Fecha"
                                ></v-checkbox>
                                <v-checkbox style="margin-top:-5%;" v-model="nombre"
                                    label="Nombre"
                                ></v-checkbox>
                                <v-checkbox style="margin-top:-5%;"  v-model="telefono"
                                    label="Teléfono"
                                ></v-checkbox>
                                <v-checkbox style="margin-top:-5%;" v-model="email"
                                    label="Email"
                                ></v-checkbox>
                                <v-checkbox style="margin-top:-5%;" v-model="ciudad"
                                    label="Ciudad"
                                ></v-checkbox>
                                <v-checkbox style="margin-top:-5%;" v-model="estacion"
                                    label="Estación"
                                ></v-checkbox>
                                <v-checkbox style="margin-top:-5%;" v-model="retomobil"
                                    label="Usuario Reto Mobil"
                                ></v-checkbox>
                                <v-checkbox style="margin-top:-5%;" v-model="monto"
                                    label="Monto de compra"
                                ></v-checkbox>
                                <v-checkbox style="margin-top:-5%;" v-model="folio"
                                    label="Folio ticket"
                                ></v-checkbox>
                            </div >
                            <small class="text-danger" v-if="errors.includes( 'check' )">Minimo 1 campo requerido</small>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form>
                                <b-form-group class="mt-4">
                                    <h6 style="color:#013352;">Exhibición</h6>
                                    <br>
                                    <v-checkbox style="margin-top:-5%;" v-model="exhibicion"  label="Promoción prioritaria"  ></v-checkbox>
                                    <!-- <b-form-datepicker  v-model="exhibicion" placeholder="Fecha de Exhibición"></b-form-datepicker> -->
                                    <!-- <small class="text-danger">{{ errorNombre }}</small> -->
                                </b-form-group>
                            </b-form>
                            <b-form>
                                <b-form-group class="mt-4">
                                    <h6 style="color:#013352;">Vigencia desde</h6>
                                    <b-form-datepicker v-model="desde"  placeholder="Vigencia desde"></b-form-datepicker>
                                    <small class="text-danger" v-if="errors.includes( 'desde' )">Campo requerido</small>
                                </b-form-group>
                            </b-form>
                            <b-form>
                                <b-form-group class="mt-4">
                                    <h6 style="color:#013352;">Vigencia hasta</h6>
                                    <b-form-datepicker v-model="hasta"  placeholder="Vigencia hasta"></b-form-datepicker>
                                    <small class="text-danger" v-if="errors.includes( 'hasta' )">Campo requerido</small>
                                </b-form-group>
                            </b-form>
                        </b-col>
                    </b-row>
                 </div>    
                 <div v-else-if="grafico == 1">
                    <br>
                    <v-img style="margin-left:10%;" src="https://eligeganar.com/logo/mobil-color.png" max-width="150"></v-img>
                    

                        <b-row class="justify-content-center ">
                             <b-col cols="12" md="4" >
                                <h3 class="text-center" style="color:#013352;">Descarga de reportes</h3>
                                <br>
                                <v-btn  dark block color="#006bb7"  style="text-transform: none;" @click="baseDatos()">
                                   Base de Datos <b-spinner v-if="loader" small></b-spinner>
                                </v-btn>
                                <br>
                                <v-btn  dark block color="#006bb7"  style="text-transform: none;" @click="reportePdf()">
                                    Fotográfico de Tickets <b-spinner v-if="loader2" small></b-spinner>
                                </v-btn>
                             </b-col>
                        </b-row>
                        <br><br>
                        <hr style=" border-top: 3px solid #979797 !important;">
                        <br><br>
                                <b-row class="justify-content-center mt-2 text-center" >
                                    <b-col cols="12" >
                                        <b-card title="Aguascalientes"  class="shadow-lg border">
                                            
                                            <br>
                                            <b-card-text>
                                                <template v-if="universidad == 0 && agostaderito == 0 && lineaverde == 0 &&
                                                 montoro == 0 && morelos == 0 && ojocaliente == 0 && parque == 0 && sanfra == 0 && sananita == 0 && villas == 0
                                                  && gandhi == 0 && campestre == 0 && garza == 0 && hacienda == 0 && sauces == 0 && maravilla == 0
                                                  && siglo == 0 && alameda == 0 && industrial == 0 && ruiz == 0 && teresa == 0">
                                                   <h6 class="text-danger">Por el momento no hay registros</h6> 
                                                </template>
                                                <template v-else>
                                                   <!-- <GChart
                                                    type="ColumnChart"
                                                    :data="datachar"
                                                     :options="chartOptions"/> 
                                                    <hr> -->
                                                      <!-- <GChart
                                                      :resizeDebounce="10"
                                                        :settings="{packages: ['bar']}"    
                                                        :data="chartData"
                                                        :options="chartOptions"
                                                        :createChart="(el, google) => new google.charts.Bar(el)"
                                                        @ready="onChartReady"
                                                        
                                                    /> -->
                                                    <div id="chart">
                                                        <apexchart type="bar" height="980" :options="chartOptions" :series="series"></apexchart>
                                                    </div>
    
                                                </template>                                         
                                            </b-card-text>
                                        </b-card>
                                    </b-col>
                                    <b-col cols="12"  >
                                        <b-card title="Lagos de Moreno" class="shadow-lg border">
                                             <b>*US:</b> Participantes 
                                             <br><br>
                                            <b-card-text>
                                              <template v-if="comanja == 0 && union == 0 && lagos == 0 && jacales == 0">
                                                  <h6 class="text-danger">Por el momento no hay registros</h6> 
                                              </template>
                                              <template v-else>
                                                    <GChart
                                                    type="ColumnChart"
                                                    :data="datachar2"
                                                    />
                                              </template>                                          
                                            </b-card-text>
                                        </b-card>
                                    </b-col>
                                    <b-col cols="12" >
                                        <b-card title="Acatic" class="shadow-lg border">
                                             <b>*US:</b> Participantes 
                                             <br><br>
                                            <b-card-text>
                                                <template v-if="refugio == 0">
                                                      <h6 class="text-danger">Por el momento no hay registros</h6> 
                                                </template>
                                                <template v-else>
                                                 <GChart
                                                    type="ColumnChart"
                                                    :data="datachar3"
                                                    />
                                                </template>
                                      
                                            </b-card-text>
                                        </b-card>
                                    </b-col>
                                    <b-col cols="12" >
                                        <b-card title="Degollado" class="shadow-lg border">
                                             <b>*US:</b> Participantes 
                                             <br><br>
                                            <b-card-text>
                                                <template v-if="encinos == 0 && rojemar == 0">
                                                    <h6 class="text-danger">Por el momento no hay registros</h6> 
                                                </template>
                                                <template v-else>
                                                <GChart
                                                    type="ColumnChart"
                                                    :data="datachar4"
                                                    />
                                                </template>                                     
                                            </b-card-text>
                                        </b-card>
                                    </b-col>
                                     <b-col cols="12" class="shadow-lg border">
                                        <b-card title="Usuarios por Ciudad">
                                            <b>*UN:</b> Usuario Normal <br>
                                            <b>*URM:</b> Usuario Reto Mobil
                                                <b-card-text>                                                     
                                                    <GChart
                                                        type="ColumnChart"
                                                        :data="dataColumna"
                                                        
                                                    />
                                                </b-card-text>
                                            </b-card>   
                                      </b-col>
                                </b-row>
                                
                 
                 </div>   
                 <div v-else>
                     <b-alert show variant="warning">Importante subir archivos con extención PDF</b-alert>
                    <b-form-group>
                    <h6 style="color:#013352;">Seleccione Legal para editar</h6>
                        <select  class="form-control" v-model="legal">
                            <option value="0" selected disabled>Elige una opción</option>
                            <option value="Terminos y Condiciones">Términos y Condiciones</option>
                            <option value="Aviso de Privacidad">Aviso de Privacidad</option>
                        </select>
                        <small class="text-danger" v-if="errors.includes( 'legal' )">Campo requerido</small>      
                    </b-form-group>
                    <b-form-group class="mt-4">
                        <h6 style="color:#013352;">
                            Archivo para actualizar
                        </h6>
                        <v-file-input class="mt-4"
                            v-model="archivoLegal"
                            label="Selecciona un archivo"
                        
                        ></v-file-input>
                    </b-form-group>  
                    <small class="text-danger" v-if="errors.includes( 'archivoLegal' )">Campo requerido</small>               
                </div>      
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn  text   @click="clear()" >
                  Cerrar
              </v-btn>
                <v-btn  dark color="#013352"  style="text-transform: none;" type="submit" v-if="grafico != 1" >
                    Subir <b-spinner v-if="loader" small></b-spinner>
                </v-btn>
            </v-card-actions>
            </b-form>
          </v-card>
        </template>
   </v-dialog>
    <v-btn
        color="#013352"
        dark
        style="text-transform: none;"
        @click="teminosEdit(2)"
        class="ml-2"
    >Legales</v-btn>
    <v-tabs class="mt-5">
        <v-tab  style="text-transform: none; color:#013352;" @click="tab = 1">Promociones Vigentes</v-tab>
        <v-tab  style="text-transform: none; color:#013352;" @click="tab = 2">Historial de promociones</v-tab>
    </v-tabs>
    <div class="table-responsive">
        <div class="form-group mt-3">
            <div class="col-md-6 text-left">
                <div class="input-group">
                    <input type="text"  class="form-control input-nav-search" placeholder="Nombre de la promoción" v-model="buscar">
                </div>
            </div>
        </div>
        <table class="table table-hover table-striped mt-3" >
            <thead class="text-white text-center" style="background:#013352;">
            <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Exhibición</th>
            <th scope="col">Vigencia desde</th>
            <th scope="col">Vigencia hasta</th>
            <th scope="col"></th>
            </tr>
            </thead>
            <tbody v-if="listado.length > 0">
                <tr v-for="pro in listado" :key="pro.id" class=" text-center">
                    <td>{{pro.promocion}}</td>
                    <td>
                        <template v-if="pro.principal == 1">
                            <i class="fas fa-star" style="color:#E02A02;"></i>
                        </template>
                    </td>
                    <td>{{pro.desde}}</td>
                    <td>{{pro.hasta}}</td>
                    <td>
                        <div class="d-flex">
                        <b-button  pill size="sm" variant="outline-success" class="ml-2" @click="verGrafico(pro.id)">
                            <i class="fas fa-eye"></i>
                        </b-button>
                        <b-button  pill size="sm" variant="outline-warning" class="ml-2" @click="editaPromo(pro)" v-if="tab == 1">
                            <i class="fas fa-pen"></i>
                        </b-button>
                        <b-button  pill size="sm" class="ml-2"  variant="outline-danger"  @click="elimarPromo(pro.id)" v-if="tab == 1">
                            <i  class="fas fa-trash"></i>
                        </b-button>
                        </div>
                    </td>
                </tr>
              </tbody>
        </table>
    </div>
    <v-alert  v-if="listado.length == 0" style="margin-left:-2px;"
        border="left"
        colored-border
        type="error"
        elevation="2"
        >
        Por el momento no hay promociones vigentes. Consulta el histórico de las mismas.
    </v-alert>  
    <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
  </v-container>
</div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import moment from "moment"
import { GChart } from 'vue-google-charts'
import apexchart from 'vue-apexcharts'
  export default {
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        imagen1:null,
        imagen2:null,
        imagen3:null,
        Verimagen1:"",
        Verimagen2:"",
        Verimagen3:"",
        exhibicion:"",
        desde:"",
        hasta:"",
        fecha:false,
        nombre:false,
        telefono:false,
        email:false,
        ciudad:false,
        estacion:false,
        monto:false,
        folio:false,
        retomobil:false,
        promo:"",
        loader:false,
        loader2:false,
        editMode:"",
        errors:[],
        arrayPromo:[],
        arrayVencido:[],
        buscar:"",
        tab:1,
        exh:moment().format("YYYY-MM-DD"),
        grafico:0,
        promoid:"",
        isMobile: this.$mobile(),
        universidad:0,
        agostaderito:0,
        lineaverde:0,
        montoro:0,
        morelos:0,
        ojocaliente:0,
        parque:0,
        sanfra:0,
        sananita:0,
        villas:0,
        gandhi:0,
        campestre:0,
        garza:0,
        hacienda:0,
        sauces:0,
        maravilla:0,
        siglo:0,
        alameda:0,
        industrial:0,
        ruiz:0,
        teresa:0,
        // 
        comanja:0,
        union:0,
        lagos:0,
        jacales:0,
        // 
        refugio:0,
        // 
        encinos:0,
        rojemar:0,
        // 
        aguasNormal:0,
        aguasReto:0,
        lagoNormal:0,
        lagoReto:0,
        acaNormal:0,
        acaReto:0,
        degoNormal:0,
        degoReto:0,
        // 
        legal:0,
        archivoLegal:null,
        chartsLib: null,
        chartOptions:{}
      }
    },
    components: {
        GChart,
        apexchart
    },
    mounted(){
        this.listarPromo();
    },
    computed: {
     listado(){
         if(this.tab == 1)
         {
            if (this.buscar == '') {          
                    return this.arrayPromo.filter(item => {
                            if (this.exh <= item.hasta) {
                                return item.created_at != "";
                            }
                    });
                }else{
                    return   this.arrayPromo.filter(item => {
                         if (this.exh <= item.hasta) {
                            return   item.promocion?.toLowerCase().includes(this.buscar.toLowerCase());
                         }
                            
                    });
                }            
        }else{
            if (this.buscar == '') {          
                    return this.arrayPromo.filter(item => {
                            if (this.exh >= item.hasta) {
                                return item.created_at != "";
                            }
                    });
                }else{
                    return   this.arrayPromo.filter(item => {
                         if (this.exh >= item.hasta) {
                            return   item.promocion?.toLowerCase().includes(this.buscar.toLowerCase())
                            ;
                         }
                    });
                }     
        } 
     },
     datachar(){
        return [
            ['Estaciones', 'US' ],
            ['Universidad', this.universidad],
            ['Agostaderito', this.agostaderito],
            ['Linea Verde', this.lineaverde],
            ['Montoro', this.montoro],
            ['Morelos', this.morelos],
            ['Ojo Caliente', this.ojocaliente],
            ['Parque Mexico', this.parque],
            ['San Francisco', this.sanfra],
            ['San Anita', this.sananita],
            ['Villas', this.villas],
            ['Gandhi', this.gandhi],
            ['Campestre', this.campestre],
            ['Garza sada', this.garza],
            ['Haciendas', this.hacienda],
            ['Sauces', this.sauces],
            ['Maravillas', this.maravilla],
            ['Red Siglo XXI Norte', this.siglo],
            ['Alameda', this.alameda],
            ['CD Industrial', this.industrial],
            ['Ruiz Cortinez', this.ruiz],
            ['Santa Teresa', this.teresa],
         ]
     },
    datachar2(){
        return [
            ['Year', 'US'],
            ['Comanja', this.comanja],
            ['Unión de San Antonio', this.union],
            ['Lagos de Moreno', this.lagos],
            ['Jacales', this.jacales]
         ]
     },
    datachar3(){
        return [
            ['Year', 'US'],
            ['El Refugio', this.refugio]
         ]
     },
    datachar4(){
        return [
            ['Year', 'US'],
            ['Los Encinos', this.encinos],
            ['Rojemar', this.rojemar]
         ]
     },
     dataColumna(){
         return [
            ['Year', 'UN', 'URM'],
            ['Aguascalientes', this.aguasNormal, this.aguasReto],
            ['Lagos de Moreno', this.lagoNormal, this.lagoReto],
            ['Acatic', this.acaNormal, this.acaReto],
            ['Degollado', this.degoNormal, this.degoReto]
         ]
     },
    options() {
      return {
       chartArea: {left: 10, right: 10, top: 10, bottom: 10, width: "100%", height: "100%"}
      }
    },
    chartData(){
      return [
            ['Estaciones', 'Universidad','Agostaderito','Linea Verde','Montoro','Morelos','Ojo Caliente','Parque Mexico','San Francisco','San Anita','Villas',
            'Gandhi','Campestre','Garza sada','Haciendas','Sauces','Maravillas','Red Siglo XXI Norte','Alameda','CD Industrial','Ruiz Cortinez','Santa Teresa'],
            ['US',this.universidad, this.agostaderito , this.lineaverde, this.montoro, this.morelos, this.ojocaliente, this.parque, this.sanfra, this.sananita,
            this.villas, this.gandhi, this.campestre, this.garza, this.hacienda, this.sauces, this.maravilla, this.siglo, this.alameda, this.industrial, this.ruiz,this.teresa],
        ]
    },
    chartOptionsw () {
      if (!this.chartsLib) return null
      return this.chartsLib.charts.Bar.convertOptions({
        bars: 'horizontal', // Required for Material Bar Charts.
        height: 800,
        legend : { position:"none"},
           series: {
             
                    0: { color: "#69442B" },
                    1: { color: "#2B4A69" },
                    2: { color: "#522B69" },
                    3: { color: "#692B41" },
                    4: { color: "#62692B" },
                    5: { color: "#039E97" },
                    6: { color: "#34039E" },
                    7: { color: "#039E0C" },
                    8: { color: "#039E87" },
                    9: { color: "#979E03" },
                    10: { color: "#9E5803" },
                    11: { color: "#91C0C4" },
                    12: { color: "#C49191" },
                    13: { color: "#B091C4" },
                    14: { color: "#91C4BD" },
                    15: { color: "#dc3912" },
                    16: { color: "#ff9900" },
                    17: { color: "#109618" },
                    18: { color: "#990099" },
                    19: { color: "#0099c6" },
                    20: { color: "#06FBFF" },
                    21: { color: "#CE06FF" },
               
              },
        hAxis: { format: 'decimal' },
      })
    },
    series(){
       return  [{
            data: [this.universidad, this.agostaderito , this.lineaverde, this.montoro, this.morelos, this.ojocaliente, this.parque, this.sanfra, this.sananita,
                      this.villas, this.gandhi, this.campestre, this.garza, this.hacienda, this.sauces, this.maravilla, this.siglo, this.alameda, this.industrial, this.ruiz,this.teresa]
          }]
    }
    
    },
    methods:{
        grafico1(){
          this.chartOptions = {
                        chart: {
                        type: 'bar',
                        height: 880
                        },
                        plotOptions: {
                        bar: {
                            barHeight: '100%',
                            distributed: true,
                            horizontal: true,
                            dataLabels: {
                            position: 'bottom'
                            },
                        }
                        },
                        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                        '#f48024', '#69d2e7','#C7A8CE','#A8CECA','#A8AECE','#CEBDA8','#B6C1FF','#B6FFD9','#FFB6D1','#FFE4B6','#B6B8FF','#F1B6FF'
                        ],
                        dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            colors: ['#013352']
                        },
                        formatter: function (val, opt) {
                            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                        },
                        offsetX: 0,
                        },
                        stroke: {
                        width: 1,
                        colors: ['#fff']
                        },
                        xaxis: {
                        categories: ['Estaciones', 'Universidad','Agostaderito','Linea Verde','Montoro','Morelos','Ojo Caliente','Parque Mexico','San Francisco','San Anita','Villas',
                        'Gandhi','Campestre','Garza sada','Haciendas','Sauces','Maravillas','Red Siglo XXI Norte','Alameda','CD Industrial','Ruiz Cortinez','Santa Teresa'
                        ],
                        },
                        yaxis: {
                        labels: {
                            show: false
                        }
                        },
                        tooltip: {
                        theme: 'dark',
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                            formatter: function () {
                                return ''
                            }
                            }
                        }
                        }
                    };

        
        },
        onChartReady (chart, google) {
            this.chartsLib = google
        },
        store(e) {
            e.preventDefault();
            this.errors = [];
           
            let model = this.prepareModel()
            if(!this.promo && this.grafico == 0)
            {
                this.errors.push('promo');
            }else if(!this.imagen1 && !this.editMode && this.grafico == 0){
                this.errors.push('imagen1');
            }else if(!this.imagen2 && !this.editMode && this.grafico == 0){
                this.errors.push('imagen2');
            }else if(!this.imagen3 && !this.editMode && this.grafico == 0){
                this.errors.push('imagen3');
            }else if(!this.fecha && !this.nombre && !this.telefono && !this.email && !this.ciudad && !this.estacion && !this.monto && !this.folio && !this.retomobil && this.grafico == 0){
                this.errors.push('check');
            }else if(!this.desde && this.grafico == 0){
                this.errors.push('desde');
            }else if(!this.hasta && this.grafico == 0){
                this.errors.push('hasta');
            }else if(this.legal == 0 && this.grafico == 2){
                this.errors.push('legal');
            }else if(!this.archivoLegal && this.grafico == 2){
                this.errors.push('archivoLegal');
            }else{
                 this.loader = true;
                let request = this.grafico == 0 ? axios.post("https://api.eligeganar.com/api/promocions/store", model) : axios.post("https://api.eligeganar.com/api/promocions/legal", model);

                request.then(
                    response => {
                        console.log(response)      
                        this.loader = false;                       
                        this.listarPromo();
                        this.clear();
                        Swal.fire(
                            '¡List@!',
                            'Se añadio promoción exitosamente',
                            'success'
                        )
                    },
                    error => {
                        this.loader = false;
                        console.log(error);
                    }
                )
            }
        },
        prepareModel() {
            let model = new FormData();
            model.append("promo", this.promo)
            model.append("imagen1", this.imagen1)
            model.append("imagen2", this.imagen2)
            model.append("imagen3", this.imagen3)
            model.append("fecha", this.fecha)
            model.append("nombre", this.nombre)
            model.append("telefono", this.telefono)
            model.append("email", this.email)
            model.append("ciudad", this.ciudad)
            model.append("estacion", this.estacion)
            model.append("retomobil", this.retomobil)
            model.append("monto", this.monto)
            model.append("folio", this.folio)
            model.append("exhibicion", this.exhibicion)
            model.append("desde", this.desde)
            model.append("hasta", this.hasta)
            model.append("edit", this.editMode)
            model.append("tipo", this.legal)
            model.append("archivoLegal", this.archivoLegal)
            return model;
        },
        clear(){
            this.promo = "";
            this.imagen1 = null;
            this.imagen2 = null;
            this.imagen3 = null
            this.fecha = false;
            this.nombre = false;
            this.telefono = false;
            this.email = false;
            this.ciudad = false;
            this.estacion = false;
            this.retomobil = false;
            this.monto = false;
            this.folio = false;
            this.exhibicion = "";
            this.desde = "";
            this.hasta = "";
            this.dialog = false;
            this.editMode = "";
            this.Verimagen1 = "";
            this.Verimagen2 = "";
            this.Verimagen3 = "";
            this.legal = 0;
            this.archivoLegal = null;
            this.grafico = 0;
            this.promoid = 0;
        },
        listarPromo(){
          var url= 'https://api.eligeganar.com/api/promocions/index';
          axios.get(url).then(
                ({data}) => {
                   this.arrayPromo = data.promociones;
                  
                }
            );
        },
        elimarPromo(id){
          Swal.fire({
            title: 'Estas seguro de eliminar esta promoción?',
            text: "se eliminaran los registros ligados a ella",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#013352',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            if (result.isConfirmed) {
                var url= 'https://api.eligeganar.com/api/promocions/delete?id='+ id;
                axios.get(url).then(
                    ({data}) => {
                      console.log(data);
                      this.listarPromo();
                      Swal.fire(
                        'Eliminado!',
                        'Promocion se elimino con éxito.',
                        'success'
                        )
                    
                    }
                );
            }
            })
        },
        editaPromo(item){
            this.promo = item.promocion;
            this.Verimagen1 = item.banner;
            this.Verimagen2 = item.movil;
            this.Verimagen3 = item.dashboard;
            this.fecha = item.fecha == 1 ? true : false;
            this.nombre = item.nombre == 1 ? true : false;
            this.telefono = item.telefono == 1 ? true : false;
            this.email = item.email == 1 ? true : false;
            this.ciudad = item.ciudad == 1 ? true : false;
            this.estacion = item.estacion == 1 ? true : false;
            this.retomobil = item.retomobil == 1 ? true : false;
            this.monto = item.monto == 1 ? true : false;
            this.folio = item.folio == 1 ? true : false;
            this.exhibicion = item.principal == 1 ? true : false;
            this.desde = item.desde;
            this.hasta = item.hasta;
            this.editMode = item.id;
            this.dialog = true;            
        },
        verGrafico(id){
            this.promoid = id;
              var url= 'https://api.eligeganar.com/api/promocions/grafico?id='+ this.promoid;
                axios.get(url).then(
                    ({data}) => {
                        console.log(data);
                        this.aguasNormal = data.aguasNormal;
                        this.aguasReto = data.aguasReto;
                        this.lagoNormal = data.lagoNormal;
                        this.lagoReto = data.lagoReto;
                        this.acaNormal = data.acaNormal;
                        this.acaReto = data.acaReto;
                        this.degoNormal = data.degoNormal;
                        this.degoReto = data.degoReto;
                        this.refugio = data.refugio;
                        this.encinos = data.encino;
                        this.rojemar = data.rojemar;
                        this.comanja = data.comanja;
                        this.union = data.union;
                        this.lagos = data.lago;
                        this.jacales = data.jacales;
                        this.universidad = data.universidad;
                        this.agostaderito = data.agosta;
                        this.lineaverde = data.linea;
                        this.montoro = data.montoro;
                        this.morelos = data.morelos;
                        this.ojocaliente = data.caliente;
                        this.parque = data.parque;
                        this.sanfra = data.francisco;
                        this.sananita = data.anita;
                        this.villas = data.villas;
                        this.gandhi = data.gandhi;
                        this.campestre = data.campestre;
                        this.garza = data.garza;
                        this.hacienda = data.hacienda;
                        this.sauces = data.sauces;
                        this.maravilla = data.maravillas;
                        this.siglo = data.siglo;
                        this.alameda = data.alameda;
                        this.industrial = data.industrial;
                        this.ruiz = data.ruiz;
                        this.teresa = data.teresa;
                        this.grafico = 1;
                        setTimeout(() => {
                           this.grafico1();
                         }, 500); 
                        this.dialog = true;             
                    }
                );
        },
        baseDatos(){
          Swal.fire({
            title: 'Descargar reporte de base de datos de promoción activa',
            text: "Por favor espere y no cierre el navegador.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#013352',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            if (result.isConfirmed) {
                this.loader = true;
                var url= 'https://api.eligeganar.com/api/promocions/reporte?id='+ this.promoid;
                axios.get(url).then(
                    ({data}) => {
                      console.log(data);
                      this.loader = false;
                       window.open('https://api.eligeganar.com/storage/exceles/reporte-promo.xlsx', '_blank');                    
                    }
                );
              }
            })
        },
        reportePdf(){
          Swal.fire({
            title: 'Descargar reporte fotográfico de tickets',
            text: "Por favor espere y no cierre el navegador.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#013352',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            if (result.isConfirmed) {
                this.loader2 = true;
                var url= 'https://api.eligeganar.com/api/promocions/pdf?id='+ this.promoid;
                axios.get(url).then(
                    ({data}) => {
                      console.log(data);
                      this.loader2 = false;
                       window.open('https://api.eligeganar.com/reporte-ticket.pdf', '_blank');                    
                    }
                );
              }
            })
        },
        teminosEdit(id){
            this.grafico = id;
            this.dialog = true;
        }
    }
  }
</script>