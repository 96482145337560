import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import admin from './components/admin.vue'
import formulario from './components/formulario.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import detectMobile from "./DetectecMobile"
import VueGoogleCharts from 'vue-google-charts'
// import axios from 'axios'
import api from "./api";
// const base = axios.create({
//   baseURL: "https://api.eligeganar.com/", 
// });
// base.defaults.headers['Content-Type'] = 'multipart/form-data';

// Vue.prototype.$http = base;
Vue.prototype.$api = api;
Vue.config.productionTip = false

Vue.prototype.$mobile = detectMobile;


Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueGoogleCharts)


var router = new VueRouter({
    mode: 'history',
    routes: [
      {
        path: '/',
        component: formulario
      },
      {
        path: '/admin-promociones',
        component: admin
      }
    ]
});

const app =  new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

export default app;