<template>
<div>
    <div v-if="valida == 0">
        <template v-if="promoid">
            <template v-if="!isMobile">
                <v-img 
                    :src="'https://api.eligeganar.com/storage/banner/' + promos.banner"
                    class="grey darken-4 "
                ></v-img>
            </template>
            <template v-else>
                <v-img 
                    :src="'https://api.eligeganar.com/storage/banner/' + promos.movil"
                    class="grey darken-4 "
                ></v-img>
            </template>
        </template>
            <br>
            <v-container class="mt-5">
                <div class="text-center mt-5" style="color:#013352;">
                    <h2><b>{{promos.promocion}}</b></h2>
                </div>
                <b-form @submit="store" autocomplete="off">
                    <b-row class="justify-content-center mt-5">
                        <b-col cols="12" md="6" >
                            <v-card elevation="4" shaped outlined>                       
                                <v-card-text>
                                    <p class="text-center" > Registra tu ticket y participa.</p>
                                    <b-row>
                                        <b-col cols="3" v-if="promos.fecha">
                                            <p>Fecha:</p>
                                        </b-col>
                                        <b-col cols="5" v-if="promos.fecha">
                                            <b-form-input type="date" v-model="fecha" :max="exh" ></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'fecha' )">Campo requerido</small>
                                        </b-col>
                                        <b-col cols="4" v-if="promos.fecha">
                                            <b-form-input type="time"  v-model="hora"></b-form-input>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3"  style="margin-top:-1%;" v-if="promos.nombre">
                                            <p>Nombre:</p>
                                        </b-col>
                                        <b-col cols="9"  style="margin-top:-2%;" v-if="promos.nombre">
                                            <b-form-input v-model="nombre"  placeholder="Nombre completo" ></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'nombre' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3"  style="margin-top:-1%;" v-if="promos.telefono">
                                            <p>Teléfono:</p>
                                        </b-col>
                                        <b-col cols="9"  style="margin-top:-2%;" v-if="promos.telefono">
                                            <b-form-input v-model="telefono" placeholder="Teléfono"></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'telefono' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;" v-if="promos.email">
                                            <p>Email:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;" v-if="promos.email">
                                            <b-form-input v-model="email"  placeholder="Email" ></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'email' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;" v-if="promos.ciudad">
                                            <p>Ciudad:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;" v-if="promos.ciudad">
                                            <!-- <b-form-input v-model="ciudad"  placeholder="Ciudad"></b-form-input> -->
                                            <select  class="form-control" v-model="ciudad">
                                                <option value="" selected disabled>Elige una opción</option>
                                                <option value="Aguascalientes">Aguascalientes</option>
                                                <option value="Lagos de Moreno">Lagos de Moreno</option>
                                                <option value="Acatic">Acatic</option>
                                                <option value="Degollado">Degollado</option>
                                            </select>
                                            <small class="text-danger" v-if="errors.includes( 'ciudad' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;" v-if="promos.estacion">
                                            <p>Estación:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;" v-if="promos.estacion">
                                            <!-- <b-form-input v-model="estacion"  placeholder="Estación"></b-form-input -->
                                            <select  class="form-control" v-model="estacion" :disabled="!ciudad">
                                                <option value="" selected disabled>Elige una opción</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="UNIVERSIDAD">UNIVERSIDAD</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="AGOSTADERITO">AGOSTADERITO</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="LINEA VERDE">LINEA VERDE</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="MONTORO">MONTORO</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="MORELOS">MORELOS</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="OJO CALIENTE">OJO CALIENTE</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="PARQUE MEXICO">PARQUE MEXICO</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="SAN FRANCISCO">SAN FRANCISCO</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="SAN ANITA">SAN ANITA</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="VILLAS">VILLAS</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="GANDHI">GANDHI</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="CAMPESTRE">CAMPESTRE</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="GARZA SADA">GARZA SADA</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="HACIENDAS">HACIENDAS</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="SAUCES">SAUCES</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="MARAVILLAS">MARAVILLAS</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="RED SIGLO XXI NORTE">RED SIGLO XXI NORTE</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="ALAMEDA">ALAMEDA</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="CD INDUSTRIAL">CD INDUSTRIAL</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="RUIZ CORTINEZ">RUIZ CORTINEZ</option>
                                                <option v-if="ciudad == 'Aguascalientes'" value="SANTA TERESA">SANTA TERESA</option>

                                                <option v-if="ciudad == 'Lagos de Moreno'" value="COMANJA">COMANJA</option>
                                                <option v-if="ciudad == 'Lagos de Moreno'" value="UNION DE SAN ANTONIO">UNION DE SAN ANTONIO</option>
                                                <option v-if="ciudad == 'Lagos de Moreno'" value="LAGOS DE MORENO">LAGOS DE MORENO</option>
                                                <option v-if="ciudad == 'Lagos de Moreno'" value="JACALES">JACALES</option>

                                                <option v-if="ciudad == 'Acatic'" value="EL REFUGIO">EL REFUGIO</option>

                                                <option v-if="ciudad == 'Degollado'" value="LOS ENCINOS">LOS ENCINOS</option>
                                                <option v-if="ciudad == 'Degollado'" value="ROJEMAR">ROJEMAR</option>
                                            </select>
                                            <small class="text-danger" v-if="errors.includes( 'estacion' )">Campo requerido</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;" v-if="promos.retomobil">
                                            <p>Usuario Reto Mobil:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;" v-if="promos.retomobil">
                                        <b-form-input v-model="retomobil"  placeholder="Usuario*"></b-form-input>
                                        <small class="text-danger" v-if="errors.includes( 'retomobil' )">Campo requerido</small>
                                        <p>*Este aparece debajo de tu QR en tu Wallet.</p>
                                        <h6 class="text-left" style=" color:#013352; margin-top:-2%;" >
                                                Si aún no cuentas con usuario visita: <b><a style=" color:#000;" href="https://www.retomobil.com" target="_target">www.retomobil.com</a></b>
                                            </h6>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;" v-if="promos.monto">
                                            <p>Monto de compra:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;" v-if="promos.monto">
                                            <b-form-input v-model="monto"  placeholder="Monto de compra"></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'monto' )">Campo requerido</small>
                                            <small class="text-danger" v-if="errors.includes( 'precio' )">Monto debe ser mayor a 350 pesos</small>
                                        </b-col>
                                        <!--  -->
                                        <b-col cols="3" style="margin-top:-1%;" v-if="promos.folio">
                                            <p>Folio ticket:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;" v-if="promos.folio">
                                            <b-form-input v-model="folio" placeholder="Folio ticket"></b-form-input>
                                            <small class="text-danger" v-if="errors.includes( 'folio' )">Campo requerido</small>
                                        </b-col>
                                        <b-col cols="3" class="mt-3" style="margin-top:-1%;" v-if="promos.folio">
                                            <p>Foto ticket:</p>
                                        </b-col>
                                        <b-col cols="9" style="margin-top:-2%;" v-if="promos.folio">
                                            <v-file-input
                                                label="Foto del ticket"
                                                filled
                                                prepend-icon="mdi-camera"
                                                v-model="foto"
                                            ></v-file-input>
                                            <small class="text-danger" v-if="errors.includes( 'foto' )">Campo requerido</small>
                                        </b-col>
                                    </b-row>   
                                    <br>                     
                                </v-card-text>                          
                            </v-card>
                            <div class="text-center">
                                <b-row class="justify-content-center">
                                    <b-col cols="1" md="5"></b-col>
                                    <b-col cols="11" md="7">
                                        <v-checkbox v-model="condicion"
                                            label="He leído y acepto los términos y condiciones."
                                        ></v-checkbox>  
                                        <small class="text-danger" v-if="errors.includes( 'condicion' )">Campo requerido</small>  
                                    </b-col>
                                    <b-col cols="4">
                                        <v-btn class="mt-5" block  dark color="#013352"  style="text-transform: none;" type="submit" >
                                            Enviar  <b-spinner v-if="loader" small></b-spinner>
                                        </v-btn>
                                    </b-col>
                                </b-row>    
                            </div>
                            <br>
                            <hr>
                            <br>
                            <br>
                        </b-col>
                    </b-row>
                </b-form>
        </v-container>        
    </div>
    <div v-else>
           <img v-if="!isMobile" src="https://eligeganar.com/sinpromo/mobil.png" class="img-fluid" alt="pista" style="width: 100% !important; margin-top: -7%;">
           <img v-else-if="isMobile" src="https://eligeganar.com/sinpromo/mobilm.png" class="img-fluid" alt="pista" style="width: 100% !important; margin-top: -2%;">

    </div>
</div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import moment from "moment"

  export default {
    data () {
      return {
        loader:false,
        editMode:false,
        errors:[],
        promos:{},
        isMobile: this.$mobile(),
        fecha:"",
        hora:"",
        nombre:"",
        telefono:"",
        email:"",
        ciudad:"",
        estacion:"",
        retomobil:"",
        monto:"",
        folio:"",
        foto:null,
        promoid:"",
        condicion:false,
        dialog:false,
        exh:moment().format("YYYY-MM-DD"),
        valida:0
      }
    },
    mounted(){
        this.promoActual();
    },
    methods:{
        store(e) {
            e.preventDefault();
            this.errors = [];
           
            let model = this.prepareModel()
            if(!this.fecha && this.promos.fecha || !this.hora && this.promos.fecha)
            {
                this.errors.push('fecha');
            }else if(!this.nombre && this.promos.nombre){
                this.errors.push('nombre');
            }else if(!this.telefono && this.promos.telefono){
                this.errors.push('telefono');
            }else if(!this.email && this.promos.email){
                this.errors.push('email');
            }else if(!this.ciudad && this.promos.ciudad){
                this.errors.push('ciudad');
            }else if(!this.estacion && this.promos.estacion){
                this.errors.push('estacion');
            }else if(!this.monto && this.promos.monto){
                this.errors.push('monto');
            }else if(parseInt(this.monto) < 350 && this.promos.monto){
                this.errors.push('precio');
            }else if(!this.folio && this.promos.folio){
                this.errors.push('folio');
            }else if(!this.foto && this.promos.folio){
                this.errors.push('foto');
            }else if(!this.condicion){
                this.errors.push('condicion');
            }else{
                this.loader = true;
                let request = axios.post("https://api.eligeganar.com/api/registro/store", model);
                request.then(
                    response => {
                        console.log(response)      
                        this.loader = false;  
                        if (response.data.valida == 1) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Folio repetido',
                                text: 'Ya existe un registro con este folio.'
                            })
                        }else if(response.data.valida == 2){
                            Swal.fire({
                                icon: 'error',
                                title: 'Usuario invalido',
                                text: 'Usuario Reto Mobil no valido.'
                            })
                        }else{
                            this.clear();
                            Swal.fire(
                                '¡List@!',
                                'Registro exitoso, sigue participando.',
                                'success'
                            )
                        }
                    },
                    error => {
                        this.loader = false;
                        console.log(error);
                    }
                )
            }
        },
        prepareModel() {
            let model = new FormData();
            model.append("promo", this.promoid)
            model.append("fecha", this.fecha)
            model.append("hora", this.hora)
            model.append("nombre", this.nombre)
            model.append("telefono", this.telefono)
            model.append("email", this.email)
            model.append("ciudad", this.ciudad)
            model.append("estacion", this.estacion)
            model.append("retomobil", this.retomobil)
            model.append("monto", this.monto)
            model.append("folio", this.folio)
            model.append("foto", this.foto)
            return model;
        },
        clear(){
            this.fecha = "";
            this.hora = "";
            this.nombre = "";
            this.telefono = "";
            this.email = "";
            this.ciudad = "";
            this.estacion = "";
            this.retomobil = "";
            this.monto = "";
            this.folio = "";
            this.foto = null;
            this.condicion = false;
        },
        promoActual(){
          var url= 'https://api.eligeganar.com/api/promocions/actual';
          axios.get(url).then(
                ({data}) => {
                   this.valida = data.valida;  
                   this.promos = data.promociones;  
                   this.promoid = this.promos.id;    
                   
                }
            );
        }
    }
  }
</script>