<template>
    <v-app>
        <router-view ></router-view>
        <Footer v-if="valida == 0" class="mt-5"></Footer>
    </v-app>
</template>

<script>
import Footer from './components/footer';
import axios from 'axios'
export default {
  name: 'App',
  data(){
    return {
      valida:0
    }
  },
  components: {
    Footer,
  },
  mounted(){
      this.promoActual();
  },
  methods:{
    promoActual(){
        var url= 'https://api.eligeganar.com/api/promocions/actual';
        axios.get(url).then(
              ({data}) => {
                  this.valida = data.valida; 
              }
          );
      }
  }
}
</script>
