<template>
  <v-footer
    dark
    padless
    
  >
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        style="background:#013352;"
      >
        
        <v-card>
          <v-toolbar
            dark
            color="#013352"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="footer == 1">Términos y Condiciones</v-toolbar-title>
            <v-toolbar-title v-else-if="footer == 2">Aviso de Privacidad</v-toolbar-title>
            <v-toolbar-title v-else-if="footer == 3">Contáctanos</v-toolbar-title>
          </v-toolbar>
          <v-container class="mt-5">
              <v-img src="https://eligeganar.com/logo/mobil-color.png" max-width="150"></v-img>
              <br><br>
              <hr style=" border-top: 3px solid #979797 !important;">
            <template v-if="footer == 1 || footer == 2">     
                <pdf :src="$api.baseUriFiles +'legales/' + archivo"
                    @num-pages="pageCount = $event"
                    @page-loaded="currentPage = $event"
                    :page="page"
                ></pdf>
                  <center v-if="pageCount > 1">
                  <b class="text-center" style="margin-top:-2%;">{{currentPage}} / {{pageCount}}</b>                 
                  <b-row class="justify-content-center"  style="margin-top:-2%;">
                  <b-col cols="5" md="3">
                      <b-button style="background:#013352; border-color:#013352;" block variant="primary"  :disabled="page == 1" @click="page = page - 1">Anterior</b-button>
                  </b-col>
                  <b-col cols="5" md="3">
                      <b-button block style="background:#013352; border-color:#013352;" variant="primary" :disabled="page == pageCount" @click="page = page + 1">Siguiente</b-button>
                  </b-col>
                  </b-row>
                  <br>
                  </center>
           </template>
           <template v-else>
                <div class="text-center mt-5" style="color:#013352;">
                    <h2><b>Contacto</b></h2>
                    <p>Déjanos tu mensaje y dinos cómo podemos ayudarte.</p>
                </div>
                <b-form @submit="store" autocomplete="off">
                  <b-row class="justify-content-center mt-5">
                      <b-col cols="12" md="6" >
                            <v-card elevation="4" shaped outlined>                       
                              <v-card-text>
                                <br><br>
                                  <b-row>
                                      <!--  -->
                                      <b-col cols="3"  style="margin-top:-1%;">
                                          <p>Nombre:</p>
                                      </b-col>
                                      <b-col cols="9"  style="margin-top:-2%;" >
                                          <b-form-input v-model="nombre"  placeholder="Nombre completo" ></b-form-input>
                                          <small class="text-danger" v-if="errors.includes( 'nombre' )">Campo requerido</small>
                                      </b-col>
                                      <!--  -->
                                      <b-col cols="3" style="margin-top:-1%;">
                                          <p>Email:</p>
                                      </b-col>
                                      <b-col cols="9" style="margin-top:-2%;">
                                          <b-form-input v-model="email"  placeholder="Email" ></b-form-input>
                                          <small class="text-danger" v-if="errors.includes( 'email' )">Campo requerido</small>
                                      </b-col>
                                      <!--  -->
                                      <b-col cols="3"  style="margin-top:-1%;">
                                          <p>Asunto:</p>
                                      </b-col>
                                      <b-col cols="9"  style="margin-top:-2%;" >
                                          <b-form-input v-model="asunto"  placeholder="Asunto" ></b-form-input>
                                          <small class="text-danger" v-if="errors.includes( 'asunto' )">Campo requerido</small>
                                      </b-col>  
                                      <!--  -->
                                      <b-col cols="3"  style="margin-top:-1%;">
                                          <p>Mensaje:</p>
                                      </b-col>
                                      <b-col cols="9"  style="margin-top:-2%;" >
                                              <b-form-textarea
                                                id="textarea"
                                                v-model="text"
                                                placeholder="Escribe aquí..."
                                                rows="3"
                                                max-rows="6"
                                              ></b-form-textarea>
                                          <small class="text-danger" v-if="errors.includes( 'text' )">Campo requerido</small>
                                      </b-col>  
                                  </b-row>   
                                  <br><br>
                              </v-card-text>
                            </v-card>
                            <center>
                              <br>
                              <v-btn class="mt-5"   dark color="#013352"  style="text-transform: none;" type="submit" >
                                  Enviar  <b-spinner v-if="loader" small></b-spinner>
                              </v-btn>
                            </center>
                      </b-col>
                  </b-row>
                </b-form>
           </template>
          </v-container>        
        </v-card>
      </v-dialog>
    </v-row>
    <v-card
      flat
      tile
      class=" lighten-1 white--text text-center"
      style="background:#013352; width: 100%;"
    >
      <v-card-text class="white--text pt-0">
          <br>

         <div class="text-center" style="font-size: 16px;">
                <span style="cursor: pointer;" @click="dialogOpen(1)">Términos y Condiciones</span> | <span style="cursor: pointer;" @click="dialogOpen(2)">Aviso de Privacidad</span>  |
                <span style="cursor: pointer;" @click="dialogOpen(3)">Contáctanos</span>
                
         </div>


      </v-card-text>
      <br>
    </v-card>
  </v-footer>
</template>
<script>
import Swal from 'sweetalert2'
import pdf from 'vue-pdf'
import axios from 'axios'
  export default {
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        footer:0,
        errors:[],
        nombre:"",
        email:"",
        asunto:"",
        text:"",
        loader:false,
        archivo:"",
        // 
        currentPage: 0,
        pageCount: 0,
        page:1,
      }
    },
    components:{
      pdf
    },
    methods:{
      dialogOpen(id){
        this.footer = id;
        this.dialog =  id == 3 ? true: false;
        this.currentPage = 0;
        this.pageCount = 0;
        this.page = 1;
        if (this.footer != 3) {
          var url= 'https://api.eligeganar.com/api/promocions/legal?id='+ this.footer;
             axios.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.dialog = true; 
                    this.archivo = data.archivo;   
                         
                }
            );
        }
      },
      store(e) {
            e.preventDefault();
            this.errors = [];
           
            let model = this.prepareModel()
           if(!this.nombre){
                this.errors.push('nombre');
            }else if(!this.email ){
                this.errors.push('email');
            }else if(!this.asunto){
                this.errors.push('asunto');
            }else if(!this.text){
                this.errors.push('text');
            }else{
                this.loader = true;
                axios.post("https://api.eligeganar.com/api/registro/contacto", model).then(
                    response => {
                        console.log(response)      
                        this.loader = false;  
                            this.clear();
                            Swal.fire(
                                '¡List@!',
                                'Tu correo se ha enviado exitosamente.',
                                'success'
                            )
                    },
                    error => {
                        this.loader = false;
                        console.log(error);
                    }
                )
            }
      },
      prepareModel() {
            let model = new FormData();
            model.append("nombre", this.nombre)
            model.append("email", this.email)
            model.append("asunto", this.asunto)
            model.append("mensaje", this.text)
            return model;
      },
      clear(){
        this.nombre = "";
        this.email = "";
        this.asunto = "";
        this.text = "";
        this.errors = [];
      },
    }
  }
</script>